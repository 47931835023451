import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import { Link } from "gatsby"
import React from "react"

import ItecLogo from "./itecLogo"

export interface ComboCourseCardProps {
  title: string
  link: string
}

const ComboCourseCard = (props: ComboCourseCardProps) => {
  const { title, link } = props
  return (
    <Link
      to={`${link}`}
      aria-label={`${title} info`}
      style={{ textDecoration: "none", flex: 1 }}
    >
      <Card raised>
        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginBottom: "12px",
              height: "50px",
            }}
          >
            <ItecLogo />
          </div>
          <article>
            <header>
              <h3 style={{ marginBottom: 0 }}>{title}</h3>
            </header>
          </article>
        </CardContent>
        <CardActions>
          <Button size="medium">Learn More</Button>
        </CardActions>
      </Card>
    </Link>
  )
}
export default ComboCourseCard
